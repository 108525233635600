import AuthService from './auth.service'

class ApplicationConfiguration {
  config = {}

  async load(){
    try{
      const response = await fetch('/config/site.json', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        }
      })

      if(response.status == 200){
        const data = await response.json()
        this.config = { ...this.config, ...data }

        this.config.apiUrl = this.config.apiUrl.replace(/\/$/, '')
        this.config.appUrl = this.config.appUrl.replace(/\/$/, '')
        this.config.identityServerUrl = this.config.identityServerUrl.replace(/\/$/, '')
        this.config.messagingHubUrl = `${this.config.apiUrl}/hubs/messaging`
        this.config.spdServerUrl = this.config.spdServerUrl.replace(/\/$/, '')
      }
      else{
        throw new Error(`Error loading site configuration file, status ${response.status}`);
      }

      AuthService.init(this.config)
      return this.config;
    }
    catch(error){
       throw new Error(`Error loading site configuration: ${error.message}`);
    }

  }

  get(key){
    return this.config[key]
  }

  apiUrl(){
    return this.config.apiUrl
  }
}

export default new ApplicationConfiguration()
