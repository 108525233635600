import { UserManager, WebStorageStateStore } from 'oidc-client'

class AuthService {
  userManager = null
  config = null

  constructor() {
  }

  init(config) {
    this.config = config

    const settings = {
      userStore: new WebStorageStateStore({ store: window.localStorage }),
      authority: config.identityServerUrl,
      client_id: config.identityClientId,
      client_secret: config.identityClientSecret,
      redirect_uri: `${config.appUrl}/callback.html`,
      automaticSilentRenew: true,
      silent_redirect_uri: `${config.appUrl}/silent-renew.html`,
      response_type: 'code',
      scope: `openid profile ${config.apiName}`,
      post_logout_redirect_uri: `${config.appUrl}/logout`,
      filterProtocolClaims: true,
      loadUserInfo: true
    }

    console.log('auth settings', settings)

    this.userManager = new UserManager(settings)
  }

  isInitialized() {
    return this.userManager !== null
  }

  getAccessToken() {
    return this.userManager.getUser().then(data => {
      if (data) {
        return data.access_token
      }
      return null
    })
  }

  getUser() {
    return this.userManager.getUser().then(user => {
      return user
    })
  }

  login() {
    return this.userManager.signinRedirect()
  }

  logout() {
    const args = {
      extraQueryParams:{

        logout_uri: `${this.config.appUrl}logout`,
        client_id: this.config.identityClientId
      }
    }

    console.log('logout args', args)
    return this.userManager.signoutRedirect(args)
  }

  refresh(route) {
    return this.userManager.signinSilent(route)
  }

  async isInRole(role) {
    const user = await this.getUser()
    return this.isUserInRole(user, role)
  }

  isUserInRole(user, role) {
    return user && user.profile.role.indexOf(role) >= 0
  }

  signinRedirectCallback() {
    return this.userManager.signinRedirectCallback()
  }
}

export default new AuthService()
