import ApplicationConfiguration from '@/services/config.service'

async function init(){
  //Ensure site configurtion is loaded before initializing the app
  await ApplicationConfiguration.load()

  const Vue = (await import('vue')).default
  const App  = (await import('./App')).default
  const filters  = (await import('./filters')).default
  const components  = (await import('./plugins/components')).default
  const forms  = (await import('./plugins/forms')).default
  const router  = (await import('./router')).default
  const store  = (await import('./store')).default
  const vuetify  = (await import('./plugins/vuetify')).default
  const i18n  = (await import('./plugins/i18n')).default
  const fields  = (await import('./components/fields')).default
  const messages  = (await import('./messaging/plugin')).default

  await store.dispatch('app/initializeApp')

  Vue.config.productionTip = false

  filters.install(Vue)
  components.install(Vue)
  forms.install(Vue)
  Vue.use(fields)
  Vue.use(messages)

  new Vue({
    router,
    store,
    vuetify,
    i18n,
    created: async () => {

    },
    render: h => h(App)
  }).$mount('#app')

}

init()
